import React from 'react'
import HeroImage from "../assets/heroLogo.png"
import {FaArrowRight} from 'react-icons/fa'
import { Link } from 'react-scroll'

const Home = () => {
  return (
	<div name="home" className='h-screen w-full bg-gradient-to-b from-black to bg-gray-800 '>
		<div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
			<div className='flex flex-col justify-center h-full'>
				<h2 className='text-4xl sm:text-7xl font-bold text-white'>
					I am a Software Engineer
				</h2>
				<p className='text-gray-300 py-4 max-w-md'>
					I design and build web applications using technologies like HTML, CSS, JavaScript, React and Tailwind.
				</p>

				<div>
					<Link to='projects' smooth duration={500} className='text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
					 from-purple-950 to bg-pink-500 cursor-pointer group'>
						Projects
						<span className='group-hover:rotate-90 duration-300'>
						<FaArrowRight size={25} className='ml-1'/>
						</span>
					</Link>
				</div>
			</div>

			<div>
				<img src={HeroImage} alt="" 
				className='rounded-2xl mx-auto w-2/3 mb-10 md:w-2/3'/>
			</div>
		</div>
	</div>
  )
}

export default Home