import React from 'react'
import ecommerceApp from "../assets/projects/Ecommerce App.jpg"
import businessWebsite from "../assets/projects/HTML, CSS and JavaScript Business Website for Client.jpg"
import animatedPortfolio from "../assets/projects/3d Animated React Portfolio.jpg"
import javascriptPortfolio from "../assets/projects/JavaScript Parallax Scrolling Website.jpg"
import recipeApp from "../assets/projects/React Recipe App.jpg"


const Projects = () => {
	const project =[
		{
			id: 1,
			src: ecommerceApp,
			href: "https://ecommerce-store-4kcj2y3wu-teresamuhius-projects.vercel.app",
			profile:"https://github.com/Teresamuhiu/ecommerce-store/tree/main"

		},
		{
			id: 2,
			src: businessWebsite,
			href: "https://acerabusinesssolutions.teresamuhiu.com"
			
			
		},
		{
			id: 3,
			src: recipeApp,
			href: "https://yourwayrecipeapp.teresamuhiu.com",
			profile: "https://github.com/Teresamuhiu/Recipe-App-Your-Way/tree/master"
		
		},
		{
			id: 4,
			src: animatedPortfolio,
			href: "https://react3dportfolio.teresamuhiu.com",
			profile: "https://github.com/Teresamuhiu/react3dportfolio"
			
		},
		{
			id: 5,
			src: javascriptPortfolio,
			href: "https://animatedjavascriptportfolio.teresamuhiu.com",
			profile: "https://github.com/Teresamuhiu/Html-Css-and-JavaScript-Portfolio/tree/master"
		
		},
	]
  return (
	<div name="projects" className='bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen'>
		<div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
			<div className='pb-8'>
				<p className='text-4xl font-bold inline border-b-4 border-gray-300'>Projects</p>
				<p className='py-6'>See My Work</p>
			</div>

			<div  className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>

			{project.map(({id, src, href, profile}) => (
					<div key={id} className='shadow-md shadow-gray-600 rounded-lg'>
						<img src={src} alt="" className='rounded-md duration-300 hover:scale-105' />

						<div className='flex items-center justify-center'>
							<a href={href} target="_blank" rel="noreferrer">
							<button className='w-1/2 px-6 py-3 m-4 duration-300 hover:scale-105'>Demo</button>
							</a>
							<a href={profile} target="_blank" rel="noreferrer">
							<button className='w-1/2 px-6 py-3 m-4 duration-300 hover:scale-105'>Code</button>
							</a>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
  )
}

export default Projects