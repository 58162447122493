import React from 'react'

const Contact = () => {
  return (
	<div name="contact" className='w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white'>
		<div className='flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full'>
			<div className='pb-8'>
				<p className='text-4xl font-bold inline border-b-4 border-gray-300'>Contact Me</p>
				<p className='py-6'>I'll be happy to answer your questions</p>
			</div>

			<div className='flex justify-center items-center'>
				<form action="https://getform.io/f/ce62f9f0-bc27-4283-b4d9-34490c26ad28" method="POST"
				className='flex flex-col w-full md:w-1/2'>
				<input type="text" name="name" placeholder='Enter your name' className='p-2 bg-transparent border-2 rounded-md focus:outline-none' />
				<input type="text" name="email" placeholder='Enter your email' className='my-2 p-2 bg-transparent border-2 rounded-md focus:outline-none' />
				<textarea name="message" placeholder='Enter your message' rows="10" className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'></textarea>

				<button className='text-white bg-gradient-to-b from-purple-950 to bg-pink-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300'>Get In Touch</button>
				</form>
			</div>
		</div>
	</div>
  )
}

export default Contact