import React from 'react'

const About = () => {
  return (
	<div name="about" className='w-full h-fit bg-gradient-to-b from-gray-800 to-black text-white'>
		<div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
			<div className='pb-8'>
				<p className='text-4xl font-bold inline border-b-4 border-gray-300'>About</p>
			</div>
			<p className='text-xl mt-10 text-gray-300'>
				Hello, I'm Teresa Muhiu, a software engineer that loves to explore the intersection of creativity and technology. I enjoy creating beautiful, functional, and 
				user friendly web applications that make a positive impact on people's lives.
				I believe that a well-designed web application not only looks good but enhances
				the user experience and I strive to achieve this with every project I work on. I love collaborating
				with others and sharing ideas to create something truly special.
			</p>
			<br />
			<p className='text-xl text-gray-300'>
				Outside of work, I enjoy immersing myself in the arts, including painting and music. I believe that my love
				of art and creativity gives me a unique perspective on software engineering, allowing me to approach problems in 
				a more innovative and imaginative way.
			</p>
			<br />
			<p className='text-xl text-gray-300 mb-28'>
				My goal as a software engineer is to always keep learning and growing in my craft. I am constantly seeking out
				new technologies and approaches to improve my skills and create better solutions.
				If you're looking for a dedicated software engineer who loves what she does, I'm your person. Let's build something amazing together!
			</p>
		</div>
	</div>
  )
}

export default About