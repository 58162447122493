import React from 'react'
import cssLogo from "../assets/cssLogo.png"
import githubLogo from "../assets/githubLogo.png"
import htmlLogo from "../assets/htmlLogo.png"
import javascriptLogo from "../assets/javascriptLogo.png"
import reactLogo from "../assets/reactLogo.png"
import tailwindLogo from "../assets/tailwindLogo.png"

const Skills = () => {
	const techSkills = [
		{
			id: 1,
			src: htmlLogo,
			title: "HTML",
			style: "shadow-orange-800"
		},
		{
			id: 2,
			src: cssLogo,
			title: "CSS",
			style: "shadow-blue-400"
		},
		{
			id: 3,
			src: javascriptLogo,
			title: "JavaScript",
			style: "shadow-yellow-400"
		},
		{
			id: 4,
			src: reactLogo,
			title: "React",
			style: "shadow-sky-400"
		},
		{
			id: 5,
			src: tailwindLogo,
			title: "Tailwind CSS",
			style: "shadow-green-300"
		},
		{
			id: 6,
			src: githubLogo,
			title: "Github",
			style: "shadow-gray-300"
		},
	]
  return (
	<div name="skills" className='bg-gradient-to-b from-gray-800 to-black w-full h-screen'>
		<div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
			<div>
				<p className='text-4xl font-bold border-b-4 border-gray-300 p-2 inline'>Skills</p>
				<p className='py-6'>Familiarity with these web technologies</p>
			</div>

			<div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-3 text-center py-8 px-12 sm:px-0'>

				{
					techSkills.map(({id, src, title, style}) => (
						<div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
							<img src={src} alt="" className='w-20 mx-auto' />
							<p className='mt-4'>{title}</p>
						</div>
					))
				}		
			</div>
		</div>
	</div>
  )
}

export default Skills