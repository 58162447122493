import React from 'react'
import { FaGithub} from 'react-icons/fa'
import {HiOutlineMail} from "react-icons/hi"
import {BsFillPersonLinesFill} from "react-icons/bs"

const SocialLinks = () => {
	const socialLinks = [
		{
		id: 1,
		child: (
			<>
				Github <FaGithub size={30} />
			</>
		),
		href: "https://github.com/Teresamuhiu",
		style: "rounded-tr-md",
		},
		{
			id: 2,
			child: (
				<>
				Email <HiOutlineMail size={30} />
				</>
			),
			href: "mailto:username@gmail.com",
			},
			{
				id: 3,
				child: (
					<>
					CV <BsFillPersonLinesFill size={30} />
					</>
				),
				href: "/Teresa_Muhiu_SWE_2024.pdf",
				download: true,
				},
	];
  return (
	<div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
		<ul>
			{socialLinks.map(({ id, child, href, style, download })=>(
				<li 
				key={id} 
				className={
					"flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gradient-to-r from-purple-950 to bg-pink-500" +
					 " " + style}>
			<a 
				href={href}
				className='flex justify-between items-center w-full text-white'
				download={download}
				target="_blank"
				rel="noreferrer"
			>
   				{child}
		</a>
	</li>
	))}
		
	</ul>
	</div>
  )
}

export default SocialLinks